<template>
  <el-dialog
    title=""
    custom-class="register-form"
    :visible.sync="registerStatus"
    :before-close="beforeClose"
    :show-close="false"
  >
    <div class="register">
      <div class="contleft">
        <div class="logo">
          <img :src="pageConfig.loginLogo" />
        </div>
      </div>
      <div class="contright">
        <div class="top">
          <img
            @click="beforeClose()"
            src="../../assets/icon/close.svg"
            alt=""
          />
        </div>
        <div class="registerBox">
          <div class="title">Create an account</div>
          <div class="registerForm">
            <el-form
              v-show="active === 0"
              ref="registerForm0"
              :rules="rules"
              :model="registerForm"
              label-width="0px"
            >
              <div class="inputBox">
                <div class="inputTitle">Email</div>
                <el-form-item prop="registerEmail">
                  <el-input
                    v-model="registerForm.registerEmail"
                    placeholder="Email"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
            <el-form
              v-show="active === 1"
              ref="registerForm1"
              :rules="rules"
              :model="registerForm"
              label-width="0px"
            >
              <div class="inputBox">
                <div class="inputTitle">Email</div>
                <el-form-item prop="registerEmail">
                  <el-input
                    v-model="registerForm.registerEmail"
                    placeholder="Email"
                    readonly
                  ></el-input>
                </el-form-item>
              </div>
              <div class="inputBox">
                <div class="inputTitle">Verification Code</div>
                <el-form-item prop="regNumCode">
                  <el-input
                    v-model="registerForm.regNumCode"
                    placeholder="Verification Code"
                  >
                    <template slot="append">
                      <el-button
                        :loading="codeLoading"
                        style="cursor: pointer"
                        @click="getCodeTimer"
                      >
                        {{ !isCode ? "Get Code" : `${num}s` }}
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
              </div>
            </el-form>
            <el-form
              v-show="active === 2"
              ref="registerForm2"
              :rules="rules"
              :model="registerForm"
              label-width="0px"
            >
              <div class="inputBox">
                <div class="inputTitle">Password</div>
                <el-form-item prop="CreatePassword">
                  <el-input
                    v-model="registerForm.CreatePassword"
                    show-password
                    placeholder="Password"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="inputBox">
                <div class="inputTitle">Re-Password</div>
                <el-form-item prop="regPasswords">
                  <el-input
                    v-model="registerForm.regPasswords"
                    show-password
                    placeholder="Re-Password"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="btns">
              <el-button class="back" @click="prev" v-show="active > 0"
                >Back</el-button
              >
              <el-button
                v-show="active === 2"
                :loading="loading"
                class="register-btn"
                @click="setSubmit"
                >Register</el-button
              >
              <el-button @click="next" class="next" v-show="active < 2"
                >Next</el-button
              >
            </div>
          </div>
          <template> </template>
        </div>
        <div class="registered">
          <span>Existing accounts，</span>
          <el-button type="text" @click="loginShow">Now Login</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { emailRules, passwordRules } from "@/utils/rules";
import { register, getMailCode } from "@/api/index";
import eventBus from "../login/eventBus.js";
import { mapState } from "vuex";
export default {
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!passwordRules(value)) {
        callback(
          new Error(
            "Password at least 6 characters (including letters and numbers)"
          )
        );
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter your password again"));
      } else if (value !== this.registerForm.CreatePassword) {
        callback(new Error("Two input password is inconsistent!"));
      } else {
        callback();
      }
    };
    return {
      registerForm: {
        registerEmail: "",
        CreatePassword: "",
        regPasswords: "",
        regNumCode: "",
        agreementCheck: false,
      },
      loading: false,
      codeLoading: false,
      rules: {
        registerEmail: [{ validator: emailRules(), trigger: "blur" }],
        CreatePassword: [
          {
            required: true,
            message: "Please input a password",
            trigger: "blur",
          },
          { validator: validatePassword, trigger: "blur" },
        ],
        regPasswords: [
          {
            required: true,
            message: "Please repeat the password",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
          { validator: validatePassword, trigger: "blur" },
        ],
        regNumCode: [
          {
            required: true,
            message: "Please enter the verification code",
            trigger: "blur",
          },
        ],
      },
      timer: null,
      num: 60,
      isCode: false,
      active: 0,
    };
  },
  computed: mapState({
    pageConfig: (state) => state.common.pageConfig,
    registerStatus() {
      return this.$store.state.register.registerStatus;
    },
  }),
  methods: {
    prev() {
      this.active--;
      if (this.active <= 0)
        // this.goSubmit();.
        this.active = 0;
    },
    next() {
      this.$refs[`registerForm${this.active}`].validate((valid) => {
        if (valid) {
          if (this.active++ > 2) return;
        } else {
          return false;
        }
      });
    },
    beforeClose() {
      this.$store.dispatch("register/set_registerStatus", false);
    },
    setSubmit() {
      this.$refs["registerForm2"].validate((valid) => {
        if (valid) {
          this.goSubmit();
        } else {
          return false;
        }
      });
    },

    getCodeTimer() {
      if (this.isCode) return;

      const verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (!verify.test(this.registerForm.registerEmail))
        // return this.$message.error("Please fill in the correct email address");
        return this.$notify({
          title: "error",
          message: "Please fill in the correct email address",
          type: "error",
        });
      this.codeLoading = true;
      getMailCode({
        email: this.registerForm.registerEmail,
        lang: "en",
        use: "1",
      }).then((res) => {
        const results = res.data;
        // const data = JSON.parse(results.match(/(.*?){"d":null}/)[1]);
        if (results.code == 200) {
          this.isCode = true;
          if (!this.timer) {
            this.timer = setInterval(() => {
              if (this.num > 0 && this.num <= 60) {
                this.num--;
              } else {
                this.isCode = false;
                clearInterval(this.timer);
                this.timer = null;
                this.num = 60;
              }
            }, 1000);
          }
        }
        this.codeLoading = false;
      });
    },
    goSubmit() {
      register({
        email: this.registerForm.registerEmail,
        password: this.registerForm.CreatePassword,
        code: Number(this.registerForm.regNumCode),
        // givename: "",
        // surname: "",
      }).then((res) => {
        const results = res.data;
        if (res.status === 200) {
          const data = results;
          if (results.code === 200) {
            // this.$message.success({
            //   message: "Register successfully. Log in later",
            // });
            this.$notify({
              title: "success",
              message: "Register successfully. Log in later",
              type: "success",
            });

            setTimeout(() => {
              // this.nowLogin();
              this.beforeClose();
              eventBus.$emit("goLogin", {
                email: this.registerForm.registerEmail,
                password: this.registerForm.CreatePassword,
              });
            }, 2000);
          } else if (results.code == 300) {
            // this.$message.error({
            //   message: "The email address has been registered",
            // });
            this.$notify({
              title: "error",
              message: "The email address has been registered",
              type: "error",
            });
          } else if (results.code == 400) {
            // this.$message.error({
            //   message: "Interface call failure",
            // });
            this.$notify({
              title: "error",
              message: "Interface call failure",
              type: "error",
            });
          } else if (results.code == 502) {
            // this.$message.error({
            //   message: "Verification code expired",
            // });
            this.$notify({
              title: "error",
              message: "Verification code expired",
              type: "error",
            });
          } else if (results.code == 500) {
            // this.$message.error({
            //   message: "No verification code",
            // });
            this.$notify({
              title: "error",
              message: "No verification code",
              type: "error",
            });
          } else if (results.code == 501) {
            // this.$message.error({
            //   message: "Verification code error",
            // });
            this.$notify({
              title: "error",
              message: "Verification code error",
              type: "error",
            });
          }
        }
      });
    },
    loginShow() {
      this.$store.dispatch("register/set_registerStatus", false);
    },
    agreementShow() {
      this.$store.dispatch("register/set_agreementStatus", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./register.scss";
</style>
