const state = {
  Currency: sessionStorage.getItem("Currency") || "AUD", // 货币类型
  pageConfig: {}, // 网页配置
};
const mutations = {
  SET_CURRENCY(state, value) {
    state.Currency = value;
  },
  setPageConfig(state, value) {
    value['loginLogo'] = `${value['loginLogo']}&t=${new Date().getTime()}`
    state.pageConfig = value;
  },
};
const actions = {
  set_Currency(context, value) {
    context.commit("SET_CURRENCY", value);
  },
  set_PageConfig(context, value) {
    context.commit("setPageConfig", value);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
