export function dragScrollBar(Vue) {
    Vue.directive('dragScrollBar', {
        bind(el, binding) {
            // 通过 $refs 传入 el-scrollbar 实例
            const scrollbar = binding.value?.scrollbar;
            if (!scrollbar || !scrollbar.wrap) return;

            let isDragging = false;
            let startX = 0;
            let startScrollLeft = 0;

            // 鼠标按下事件
            el.onmousedown = (e) => {
                isDragging = true;
                startX = e.pageX;
                // 记录初始滚动位置
                startScrollLeft = scrollbar.$refs.wrap.scrollLeft;


                // 禁用文本选择
                document.onselectstart = () => false;
                document.ondragstart = () => false;

                document.onmousemove = (de) => {
                    if (!isDragging) return;
                    const deltaX = de.pageX - startX;
                    // 设置滚动条的位置
                    scrollbar.$refs.wrap.scrollLeft = startScrollLeft - deltaX;
                };

                document.onmouseup = () => {
                    isDragging = false;
                    document.onmousemove = document.onmouseup = document.onselectstart = document.ondragstart = null;
                };
            };
        },
        unbind() {
            // 解绑时清理事件
            document.onmousemove = null;
            document.onmouseup = null;
            document.onselectstart = null;
            document.ondragstart = null;
        }
    });
}
