import request from "@/utils/request"

function serialize(data) {
  return Object.entries(data)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")
}

// 上传图片
export function setUploadApi(data) {
  return request({
    url: "/v3/common_app/upload",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

// 获取图片
export function getUploadApi(path) {
  return `${process.env.NODE_ENV === "development" ? "/api" : ""}/v3/common_app/upload/${path}`
}

export function TicketQuery(data) {
  return request({
    url: "/v2/ticket/ticketSearchNew",
    method: "post",
    data,
  })
}

// 重置密码
export function resetting(data) {
  return request({
    url: "/service/userService.asmx/seekPwd",
    method: "post",
    data,
  })
}

// 修改密码
export function userPwd(data) {
  return request({
    url: "/service/userService.asmx/seekPwd",
    method: "post",
    data,
  })
}

// 我的行程（已出票订单）
export function GetOrderList(data) {
  return request({
    url: "/service/TicketInquiries.asmx/GetOrderList",
    method: "post",
    data,
  })
}
// 个人信息
export function GetuserInfo(data) {
  return request({
    url: "/service/userService.asmx/userInfo",
    method: "post",
    data,
  })
}
// 修改个人信息
export function updateuserInfo(data) {
  return request({
    url: "/service/userService.asmx/updateUserInfoV2",
    method: "post",
    data,
  })
}
// 常用乘客列表
export function userContactsList(data) {
  return request({
    url: "/service/userService.asmx/userContactsList",
    method: "post",
    data,
  })
}
// 修改常用乘客信息
export function upUserContacts(data) {
  return request({
    url: "/service/userService.asmx/upUserContactsV2",
    method: "post",
    data,
  })
}
// 添加常用旅客
export function addUserContacts(data) {
  return request({
    url: "/service/userService.asmx/userContactsV2",
    method: "post",
    data,
  })
}
// 删除常用旅客
export function DeleteContacts(data) {
  return request({
    url: "/service/userService.asmx/DeleteContacts",
    method: "post",
    data,
  })
}
// 验票
export function TicketVerify(data) {
  return request({
    url: "/service/TicketInquiries.asmx/TicketVerify",
    method: "post",
    data,
  })
}
// 生成订单
export function Book(data) {
  return request({
    url: "/service/TicketInquiries.asmx/Book",
    method: "post",
    data,
  })
}
// PayVerify 支付验证
export function PayVerify(data) {
  return request({
    url: "/service/TicketInquiries.asmx/PayVerify",
    method: "post",
    data,
  })
}
// payAll 支付
export function payAll(data) {
  return request({
    url: "/service/TicketInquiries.asmx/payAll",
    method: "post",
    data,
  })
}

// infomation 验价接口
/**
  "adultNumber": 0, // 成人人数
  "callback": "string",
  "childNumber": 0, // 儿童人数
  "currency": "string",
  "data": "string", // data唯一标识
  "infantNumber": 0,
  "requesttype": "string",
  "tripType": 0
 */
export function getTicketVerify(data) {
  return request({
    url: "/v2/ticket/ticketVerify",
    method: "post",
    data,
  })
}

// 生单接口
export function setTicketBooking(data) {
  return request({
    url: "/v2/ticket/ticketBooking",
    method: "post",
    data,
  })
}
// pay 支付
export function setTicketPay(data) {
  return request({
    url: "/v2/ticket/ticketPay",
    method: "post",
    data,
  })
}

// 查询订单List
export function commonQuery(data) {
  return request({
    url: "/v2/order/commonQuery",
    method: "post",
    data,
  })
}

// 1注册 2找回 3订单
// 获取验证码
export function getMailCode(data) {
  return request({
    url: "/v2/getMailCode",
    method: "post",
    data,
  })
}

// 登录
export function login(data) {
  return request({
    url: "/v3/login",
    method: "post",
    data,
  })
}

// 退出
export function logout(data) {
  return request({
    url: "/v3/logout",
    method: "post",
    data,
  })
}

// 注册
export function register(data) {
  return request({
    url: "/v3/register",
    method: "post",
    data,
  })
}

// 重置密码
export function resetPassword(data) {
  return request({
    url: "/v2/resetPassword",
    method: "post",
    data,
  })
}

// 获取用户信息
export function getInfo(data) {
  return request({
    url: "/v2/user/getInfo",
    method: "get",
    data,
  })
}

// 修改用户信息
export function setInfo(data) {
  return request({
    url: "/v2/user/updateInfo",
    method: "post",
    data,
  })
}

// 获取旅客列表
export function getUserPassengers(params) {
  return request({
    url: "/v2/user/queryUserPassengers",
    method: "get",
    params,
  })
}

// 更新或者添加旅客信息
export function updateUserPassenger(data) {
  return request({
    url: "/v2/user/updateUserPassenger",
    method: "post",
    data,
  })
}
// 联系人列表
export function getContacts(params) {
  return request({
    url: "/v2/user/queryUserContacts",
    method: "get",
    params,
  })
}
// 添加更新联系人
export function updateUserContact(data) {
  return request({
    url: "/v2/user/updateUserContact",
    method: "post",
    data,
  })
}
// 删除联系人
export function delUserContact(data) {
  return request({
    url: "/v2/user/delUserContact",
    method: "post",
    data,
  })
}

// 删除常旅客信息
export function delUserPassenger(data) {
  return request({
    url: "/v2/user/delUserPassenger",
    method: "post",
    data,
  })
}
// 存储
export function saveParams(data) {
  return request({
    url: "/v2/searchParams/saveParams",
    method: "post",
    data,
  })
}
// 获取存储
export function getParams(params) {
  return request({
    url: "/v2/searchParams/getParams",
    method: "get",
    params,
  })
}

// 获取城市、机场、国家
export function getgCity(params) {
  return request({
    url: "/v2/ticket/getCity",
    method: "get",
    params,
  })
}

// 查询行李
export function ticketBaggage(data) {
  return request({
    url: "/v2/ticket/ticketPrice",
    method: "get",
    params: data,
  })
}

// 获取热门航线
export function getHotlines(data) {
  return request({
    url: "/v2/static/hotlines",
    method: "get",
    params: data,
  })
}

// 获取网站顶部图标
export function getFavicon(data) {
  return request({
    url: "/v2/static/favicon",
    method: "get",
    params: data,
  })
}

// 获取网站顶部图标
export function getLogo(data) {
  return request({
    url: "/v2/static/logo",
    method: "get",
    params: data,
  })
}

// 获取关于我们
export function getAbout(data) {
  return request({
    url: "/v2/static/about",
    method: "get",
    params: data,
  })
}

// 获取协议terms
export function getTerms(data) {
  return request({
    url: "/v2/static/terms",
    method: "get",
    params: data,
  })
}
// 获取协议notices
export function getNotices(data) {
  return request({
    url: "/v2/static/notices",
    method: "get",
    params: data,
  })
}
// 获取协议terms
export function getPrivacy(data) {
  return request({
    url: "/v2/static/privacy",
    method: "get",
    params: data,
  })
}

// 获取公共信息
export function siteInfo(data) {
  return request({
    url: "/v2/static/siteInfo",
    method: "get",
    params: data,
  })
}

// 判断是否展示更多填单信息
// ?dep=sel&arr=tyo&airline=zg
export function isSimpleLineApi(data) {
  return request({
    url: "/v2/ticket/isSimpleLine",
    method: "get",
    params: data,
  })
}
export function getBagDefPrice(data) {
  return request({
    url: "/v2/public/getBagDefPrice",
    method: "get",
    params: data,
  })
}
// 获取行李
export function getBaggage(data) {
  return request({
    url: "/v3/ext/extBaggage",
    method: "post",
    data,
  })
}
//  取消订单
export function srCaSubmitApi(data) {
  return request({
    url: "/v3/ext/sr/ca/submit",
    method: "post",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  })
}
// 退票接口
export function srRfSubmitApi(query, data) {
  return request({
    url: "/v3/ext/sr/refchg2/submit?" + serialize(query),
    method: "post",
    data,
    // headers: { 'Content-Type': 'multipart/form-data' }
  })
}
// /v3/b2b/refchg2/submit
export function srRfChSubmitApi(query, data) {
  return request({
    url: "/v3/ext/sr/refchg2/submit?" + serialize(query),
    method: "post",
    data,
    // headers: { 'Content-Type': 'multipart/form-data' }
  })
}

// 获取是否需要假支付
export function getFakePay(data) {
  return request({
    url: "/v2/ticket/fakePay",
    method: "get",
    data,
  })
}
// 假支付
export function fakePay(data) {
  return request({
    url: "/v2/ticket/fakePay",
    method: "post",
    data,
  })
}
// 文章列表
export function getNewsList(data) {
  return request({
    url: "/v3/oc/front/list",
    method: "get",
    params: data,
  })
}
// 文章列表
export function getNewsInfo(data) {
  console.log(data)
  return request({
    url: "/v3/oc/front/detail",
    method: "get",
    params: data,
  })
}

// 改签搜索
export function ticketSearchApi(data) {
  return request({
    url: "/v2/ticket/ticketSearch",
    method: "post",
    data,
  })
}

// 获取可改签乘客
export function getPsgListApi(data) {
  return request({
    url: "/v3/ext/sr/refchg2/psgList",
    method: "get",
    params: data,
  })
}
