<template>
  <svg
    class="svg-icon"
    aria-hidden="true"
    :style="{ width: width, height: height }"
  >
    <use :xlink:href="iconName" />
    <!-- :fill="color" -->
  </svg>
</template>

<script>
export default {
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "16px",
    },
    height: {
      type: String,
      default: "16px",
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`
    },
  },
}
</script>
