<template>
  <div id="app">
    <Nav v-if="this.$route.path !== '/loading'"></Nav>
    <keep-alive>
      <router-view
        v-if="$route.meta.KeepAlive"
        :key="$route.fullPath"
      />
    </keep-alive>
    <router-view v-if="!$route.meta.KeepAlive" />
    <el-backtop :bottom="100"> </el-backtop>
  </div>
</template>
<script>
import Nav from "./components/nav/nav"
import Cookies from "./utils/cookies"

import { siteInfo } from "@/api/index"
export default {
  components: {
    Nav,
  },
  data() {
    return {
      isHidden: ["login", "loading"],
      isRouterAlive: true,
      data: [
        {
          orderNo: "111",
        },
      ],
    }
  },
  provide() {
    //提供
    return {
      reload: this.reload,
    }
  },
  created() {
    this.thePublicInformation()
    this.getIco()
    if (Cookies.get("token")) {
      this.$store.dispatch("login/set_loginstatus", false)
      this.$store.dispatch("login/set_loginshow", false)
      this.$store.dispatch("login/set_userInfo")
    } else {
      Cookies.remove("token")
      this.$store.dispatch("login/set_loginshow", true)
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    //获取公共信息
    thePublicInformation() {
      siteInfo().then((res) => {
        const { data, code } = res.data
        if (code == 200) {
          data["logo"] = `${process.env.NODE_ENV === "development" ? "/api" : ""}/v2/static/logo`
          // console.log(data);
          data["loginLogo"] = `${process.env.NODE_ENV === "development" ? "/api" : ""}/v2/static/logo?style=2`
          // console.log(data);
          this.$store.dispatch("common/set_PageConfig", data)
          document.title = data.site_title
        }
      })
    },
    // 获取ico
    getIco() {
      document.querySelector("link[rel=icon]").href = `${process.env.NODE_ENV === "development" ? "/api" : ""}/v2/static/favicon`
    },
  },
}
</script>

<style lang="scss">
@import "./views/home/mglobalSelect.scss";
@import "./assets/scss/responsive.scss";
@import "./styles/notification.scss";
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  flex: 1;
  width: 100%;
  .wrap {
    display: flex;
    justify-content: space-around;
  }
}

div.el-tooltip__popper.is-dark {
  font-size: 0.24rem;
}

nav {
  // padding: 30px;
  a {
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.el-message {
  font-size: 0.35rem;
  top: 120px !important;

  .el-message__content {
    font-size: 0.28rem;
  }
}
// .v-modal {
//   background: transparent !important;
// }
.el-message-box__wrapper {
  .el-message-box {
    width: 9rem !important;
    .el-message-box__header {
      // background-color: #e7e7e7;
      .el-message-box__title {
        font-size: 0.28rem;
      }
    }
    .el-message-box__content {
      padding: 0 0.4rem 10px 0.4rem !important;
    }
    .el-message-box__message {
      font-size: 0.24rem;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      // padding: 0.2rem 0;
      > p {
        line-height: 0.5rem;
      }
    }
    .el-button--primary {
      font-size: 0.24rem;
    }
  }

  .el-message-box__btns {
    .el-button {
      font-size: 0.24rem;
    }
  }
  .tzbtn {
    background-color: red !important;
    border: none;
    &:hover {
      background-color: red !important;
    }
  }
}
//搜索框 子级选项样式
.childItem {
  font-size: 0.3rem !important;
}
.infoDatePicker {
  width: 400px !important;
  margin-bottom: 10px;
  &.el-picker-panel {
    width: 400px;
    color: #000 !important;
    font-weight: 600 !important;

    .el-picker-panel__content {
      width: 94%;
    }
  }
  .el-picker-panel__content {
    width: auto;
  }
  .el-date-picker__header {
    margin: 0.3rem 0.2rem;
    text-align: center;
    span {
      font-size: 26px;
    }

    .el-picker-panel__icon-btn {
      font-size: 24px !important;
      font-weight: 700;
      margin-top: 0;
    }
  }
  .el-date-table__row {
    span {
      width: 0.5rem;
      height: 0.5rem;
      line-height: 0.5rem;
    }

    .available {
      font-size: 24px;
      height: 0.8rem;
    }
  }
  tr {
    font-size: 24px;
    font-weight: 600 !important;
    color: #000;
  }
  td {
    height: 0.8rem;
  }
}
.el-select-dropdown {
  .el-select-dropdown__wrap {
    .el-select-dropdown__item {
      height: auto;
      font-size: 24px;
      color: #000;
      // font-weight: 600;
      padding: 6px 0.27rem;
    }
  }
}
// @media screen and (max-width: 1745.45px) {
//   .el-picker-panel.el-date-picker {
//     width: 4.85rem !important;
//     .el-picker-panel__content {
//       width: 4.5rem !important;
//     }
//   }
// }
// @media screen and (max-width: 1536px) {
//   .el-picker-panel.el-date-picker {
//     width: 5.6rem !important;
//     .el-picker-panel__content {
//       width: 5.25rem !important;
//     }
//   }
// }
// @media screen and (max-width: 768px) {
//   .infoDatePicker {
//     width: 80% !important;
//   }
// }

// 订单的
.orderInfoDialog.el-dialog {
  @include respond-to("tongkuo") {
    min-height: 60% !important;
  }
  position: absolute;
  margin: 0 !important;
  min-height: 80%;
  max-height: 100%;
  left: 50%;
  top: 50%;
  @include translate(-50%, -50%);
  @extend %f-d-c;
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
    padding: 0.3rem 0.3rem;
    .el-dialog__title {
      font-size: 0.34rem;
      font-weight: 700;
    }
    .el-dialog__headerbtn {
      top: 0.23rem;
      font-size: 0.3rem;
    }
  }

  .el-dialog__body {
    //   margin: 0 0.3rem 0.3rem 0.3rem;
    //   padding-top: 0.1rem;
    margin: 0;
    padding: 0;
    flex: 1;
    @extend %f;
    overflow-y: auto;
    overflow-x: hidden;
    .addCustomer {
      flex: 1;
      @extend %f;
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.datePopperClass.el-date-picker {
  width: auto;
  .el-date-picker__header {
    .el-picker-panel__icon-btn {
      font-size: 0.26rem;
      &[aria-label*="Year"] {
        display: none;
      }
    }
    .el-date-picker__header-label {
      font-size: 0.26rem;
      margin-top: 8px;
      display: inline-block;
    }
  }
  .el-picker-panel__content {
    width: 4.4rem;
    .el-month-table,
    .el-year-table {
      font-size: 0.26rem;
    }
    .el-date-table {
      font-size: 0.26rem;
    }
  }
}
</style>
