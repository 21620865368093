<template>
  <el-dialog
    title=""
    custom-class="register-form"
    :visible.sync="forgotPwdStatus"
    :before-close="beforeClose"
    :show-close="false"
  >
    <div class="register">
      <div class="contleft">
        <div class="logo">
          <img :src="pageConfig.loginLogo" />
        </div>
      </div>

      <div class="contright">
        <div class="top">
          <img
            @click="beforeClose()"
            src="../../assets/icon/close.svg"
            alt=""
          />
        </div>
        <div class="registerBox">
          <div class="title">Retrieve password</div>
          <div class="registerForm">
            <el-form
              ref="registerForm"
              :rules="rules"
              :model="registerForm"
              label-width="0px"
            >
              <div class="inputBox">
                <div class="inputTitle">Email</div>
                <el-form-item prop="registerEmail">
                  <el-input
                    :autocomplete="off"
                    v-model="registerForm.registerEmail"
                    placeholder="Email"
                    name="Email"
                    id="Email"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="inputBox">
                <div class="inputTitle">Verification Code</div>
                <el-form-item prop="regNumCode">
                  <el-input
                    v-model="registerForm.regNumCode"
                    :autocomplete="off"
                    placeholder="Reg NumCode"
                    name="code"
                    id="code"
                  >
                    <template slot="append">
                      <el-button
                        :loading="codeLoading"
                        style="cursor: pointer"
                        @click="getCodeTimer"
                      >
                        {{ !isCode ? "Get code" : `${num}s` }}
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="inputBox">
                <div class="inputTitle">Password</div>
                <el-form-item prop="resetPassword">
                  <el-input
                    v-model="registerForm.resetPassword"
                    show-password
                    placeholder="Reset Password"
                    :autocomplete="off"
                    name="resetPassword"
                    id="resetPassword"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="inputBox">
                <div class="inputTitle">Re-Password</div>
                <el-form-item prop="resetPassword1">
                  <el-input
                    v-model="registerForm.resetPassword1"
                    show-password
                    :autocomplete="off"
                    placeholder="Reset Password"
                    name="resetPassword1"
                    id="resetPassword1"
                  ></el-input>
                </el-form-item>
              </div>
              <el-form-item>
                <div class="btns">
                  <el-button class="back" @click="beforeClose()"
                    >Back</el-button
                  >
                  <el-button
                    :loading="loading"
                    class="register-btn"
                    @click="setSubmit"
                    >Next</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { emailRules } from "@/utils/rules";
import { resetPassword, getMailCode } from "@/api/index";
import { mapState } from "vuex";
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter your password again"));
      } else if (value !== this.registerForm.resetPassword1) {
        callback(new Error("Two input password is inconsistent!"));
      } else {
        callback();
      }
    };
    return {
      registerForm: {
        registerEmail: "",
        regNumCode: "",
        resetPassword: "",
        resetPassword1: "",
      },
      loading: false,
      codeLoading: false,
      rules: {
        registerEmail: [{ validator: emailRules(), trigger: "blur" }],
        regNumCode: [
          {
            required: true,
            message: "Please enter the verification code",
            trigger: "blur",
          },
        ],
        resetPassword: [
          {
            required: true,
            message: "Please repeat the password",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
        resetPassword1: [
          {
            required: true,
            message: "Please repeat the password",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      timer: null,
      num: 60,
      isCode: false,
    };
  },
  computed: mapState({
    pageConfig: (state) => state.common.pageConfig,
    forgotPwdStatus() {
      return this.$store.state.register.forgotPwdStatus;
    },
  }),
  methods: {
    beforeClose() {
      this.$store.dispatch("register/set_forgotPwdStatus", false);
    },
    setSubmit() {
      this.$refs["registerForm"].validate((valid) => {
        if (valid) {
          this.goSubmit();
        } else {
          return false;
        }
      });
    },
    getCodeTimer() {
      if (this.isCode) return;
      const verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (!verify.test(this.registerForm.registerEmail))
        // return this.$message.error("Please fill in the correct email address");
        return this.$notify({
          title: "error",
          message: "Please fill in the correct email address",
          type: "error",
        });
      this.codeLoading = true;
      getMailCode({
        email: this.registerForm.registerEmail,
        lang: "en",
        use: "2",
      }).then((res) => {
        const results = res.data;
        // const data = JSON.parse(results.match(/(.*?){"d":null}/)[1]);
        if (results.code == 200) {
          this.isCode = true;
          if (!this.timer) {
            this.timer = setInterval(() => {
              if (this.num > 0 && this.num <= 60) {
                this.num--;
              } else {
                this.isCode = false;
                clearInterval(this.timer);
                this.timer = null;
                this.num = 60;
              }
            }, 1000);
          }
        }
        this.codeLoading = false;
      });
    },
    goSubmit() {
      resetPassword({
        email: this.registerForm.registerEmail,
        code: Number(this.registerForm.regNumCode),
        new_pass: this.registerForm.resetPassword,
      }).then((res) => {
        const results = res.data;
        if (results.code == 200) {
          // this.$message.success("Password reset successfully");
          this.$notify({
            title: "success",
            message: "Password reset successfully",
            type: "success",
          });
          this.$store.dispatch("register/set_forgotPwdStatus", false);
          // this.$store.dispatch("register/set_resetPwdStatus", true);
        } else {
          this.$alert(results.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./forgotPassword.scss";
</style>
