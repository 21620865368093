<template>
  <el-dialog
    title=""
    custom-class="login-form"
    :visible.sync="loginStatus"
    :before-close="beforeClose"
    :show-close="false"
  >
    <div class="login">
      <div class="contleft">
        <div class="logo">
          <img :src="pageConfig.loginLogo" />
        </div>
      </div>

      <div class="contright">
        <div class="top">
          <img
            @click="beforeClose()"
            src="../../assets/icon/close.svg"
            alt=""
          />
        </div>
        <div class="loginBox">
          <div class="title">Welcome</div>
          <div class="loginFrom">
            <el-form
              ref="loginForm"
              :rules="rules"
              :model="loginForm"
              label-width="0px"
            >
              <div class="inputBox">
                <div class="inputTitle">Email</div>
                <el-form-item prop="email">
                  <el-input
                    v-model="loginForm.email"
                    :placeholder="$t('m.email')"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="inputBox">
                <div class="inputTitle">Password</div>
                <el-form-item prop="password">
                  <el-input
                    type="password"
                    show-password
                    v-model="loginForm.password"
                    placeholder="Password"
                  ></el-input>
                </el-form-item>
              </div>
              <el-form-item>
                <el-button
                  type="success"
                  :loading="loading"
                  class="login-btn"
                  @click="setSubmit"
                  >Now Login</el-button
                >
              </el-form-item>
            </el-form>
            <div class="registered">
              <div
                class="create"
                @click="register"
              >
                Create an account
              </div>

              <div
                class="forgot"
                @click="forgotpassword"
              >
                Forgot password？
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div
            class="noBook"
            @click="next()"
          >
            No, just book it
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { emailRules } from "../../utils/rules"
import { login, saveParams } from "@/api/index"
import eventBus from "./eventBus"
import CookiesUtils from "@/utils/cookies"
import { mapState } from "vuex"

export default {
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      loading: false,
      rules: {
        email: [{ validator: emailRules(), trigger: "blur" }],
        password: [{ required: true, trigger: "blur" }],
      },
    }
  },
  computed: mapState({
    pageConfig: (state) => state.common.pageConfig,
    loginStatus() {
      return this.$store.state.login.loginStatus
    },
    atCurrency() {
      return this.$store.state.common.Currency
    },
  }),

  created() {
    // // console.log(this.$route.name);
    eventBus.$off("goLogin")
    eventBus.$on("goLogin", ({ email, password }) => {
      this.$set(this.loginForm, "email", email)
      this.$set(this.loginForm, "password", password)
      this.setSubmit()
    })
  },
  methods: {
    next() {
      const searchForm = JSON.parse(window.sessionStorage.getItem("searchForm"))
      const isSelectClick = window.sessionStorage.getItem("isSelectClick")
      const totalPriceEx = window.sessionStorage.getItem("totalPriceEx")
      this.$store.dispatch("login/set_loginstatus", false)
      if (isSelectClick != 1) return
      // this.$router.push({
      //   path: `/information/${window.sessionStorage.getItem("toData")}`,
      //   query: {
      //     ...searchForm,
      //     totalPriceEx,
      //   },
      // });
      // console.log(this.atCurrency);
      saveParams({
        Currency: this.atCurrency,
        adultNumber: searchForm.adultNumber,
        childNumber: searchForm.childNumber,
        data: window.sessionStorage.getItem("toData"),
        kind: searchForm.kind,
        totalPriceEx: totalPriceEx,
      }).then((res) => {
        // // console.log(res.data);
        if (res.data.code == 200) {
          // // console.log(res.data);
          this.$router.push({
            path: `/information/${res.data.data}`,
          })
        } else {
          const msgType = res.code === 200 ? "success" : "error"
          // this.$message[msgType](res.msg);
          this.$notify({
            title: msgType,
            message: res.msg,
            type: msgType,
          })
        }
      })
    },
    beforeClose() {
      this.$store.dispatch("login/set_loginstatus", false)
    },
    setSubmit() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.goSubmit()
        } else {
          return false
        }
      })
    },
    encipher() {
      const symbols = '~!@#$%^&*()_+{}":?><;.,'
      let list = [String.fromCharCode(Math.floor(Math.random() * 26 + 65)), String.fromCharCode(Math.floor(Math.random() * 26 + 96)), String.fromCharCode(Math.floor(Math.random() * 10 + 48)), symbols[Math.floor(Math.random() * symbols.length)]]

      let str = ""
      for (let i = 0; i < 8; i++) {
        str += list[Math.floor(Math.random() * 4)]
      }
      return str
    },
    goSubmit() {
      this.loading = true
      login({
        mail: this.loginForm.email,
        password: this.loginForm.password,
      }).then((res) => {
        const result = res.data
        if (res.status == 200) {
          if (result.code === 200) {
            CookiesUtils.set("token", res.headers.authorization)
            this.$store.dispatch("login/set_userInfo")
            const toInformation = window.sessionStorage.getItem("toInformation")
            if (this.$route.name === "flights" && toInformation) {
              window.sessionStorage.removeItem("toInformation")
              this.next()
            }
            CookiesUtils.set("token", this.encipher())
            this.$store.dispatch("login/set_loginstatus", false)
            this.$store.dispatch("login/set_loginshow", false)
          }
          this.loading = false
        } else if (res.status == 301) {
          // this.$message.error("Password error");
          this.$notify({
            title: "error",
            message: "Password error",
            type: "error",
          })
        }
        this.loading = false
      })
    },
    register() {
      this.$store.dispatch("register/set_registerStatus", true)
    },
    forgotpassword() {
      this.$store.dispatch("register/set_forgotPwdStatus", true)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./login.scss";
</style>
